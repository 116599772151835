@use 'styles/_variables.scss' as _;

$object-height: 54px;

.level-container {
  height: $object-height * 10;

  .object {
    height: $object-height;
    // background-color: _.$color-white;
    border: 2px solid _.$color-blue-100;

    &:hover {
      border: 2px solid _.$color-primary;
    }

    // &.active {
    //   border: 2px solid _.$color-primary;
    // }
  }
}
