@use 'styles/_variables.scss' as _;

table.disabled {
  background-color: _.$background-color-blue50;
  pointer-events: none;

  * {
    color: _.$color-blue-300;
  }
}

.ag-row-even {
  background-color: #fafafa;
}
.ag-theme-quartz,
.ag-layout-auto-height,
.ag-center-cols-viewport,
.ag-center-cols-container {
  min-height: 60px !important;
}
.ag-side-button {
  background-color: #edededcc;
  color: #3c3c3c;
  font-size: 1em;
  font-weight: 400;
  transition: background-color 0.1s ease-in;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.ag-side-button-icon-wrapper > .ag-icon {
  color: #3c3c3c;
}

.ag-side-button:hover {
  background-color: #b8b7b7;
  color: white;
}

.ag-side-button:hover .ag-side-button-icon-wrapper > .ag-icon {
  color: white;
}

.ag-side-button.ag-selected {
  background-color: #9c9c9c !important;
  outline: none !important;
}
.ag-icon-grip {
  background-color: #c7c7c700;
  border-radius: 2px;
  outline: 6px solid #c7c7c700;
}
.ag-icon-grip:hover {
  background-color: #c7c7c779;
  outline: 8px solid #c7c7c779;
  transition: all 0.2s ease-in;
}
.ag-theme-quartz {
  --ag-row-border-color: #e7e7e7;
}
.ag-cell:focus-within {
  border: 1px solid rgba(42, 100, 147, 0.242) !important;
  border-radius: 4px;
}
button:focus:not(:focus-visible) {
  outline: none;
}

.clickable {
  cursor: pointer;
  transition: color 0.2s ease;
  & .ag-group-value {
    color: #2978c1;
  }
  &:hover .ag-group-value:not(.ag-group-expanded):not(.ag-group-contracted) {
    color: #5aa3e8;
    text-decoration: underline;
  }
}