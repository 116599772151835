@use 'styles/_variables.scss' as _;

.wsBody {
  background-color: _.$color-background;
}

.wsContainer > div:first-child {
  display: flex;
  align-items: center;
  height: 100%;
}

.wsClose {
  cursor: pointer;
}
