@use 'styles/_variables.scss' as _;

.card.disabled {
  background-color: _.$background-color-blue50;
  pointer-events: none;

  * {
    color: _.$color-blue-300;
  }
}

.card > .card-header .hover:hover {
  color: _.$color-primary;
  cursor: pointer;
}
