.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vh;
  height: 80vh;
  flex-direction: column;
}

.centeredBox {
  background-color: #FFFFFF;
  box-shadow: 0px 16px 40px rgba(109, 133, 145, 0.2);
  border-radius: 10px;
  width: 475px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}
