@use 'styles/_variables.scss' as _;

.accordion-header {
  button {
    background-color:white;
    border-bottom: 1px solid _.$color-blue-700;

    &:last-of-type {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    &:first-of-type {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
  }
}

.accordion {
  --bs-accordion-inner-border-radius: 0;
}