@use 'styles/_variables.scss' as _;

// all variables are available here: \node_modules\bootstrap\scss\_variables.scss
// I did not copy all the file to avoid problems while upgrading bootstrap, so you need to check only few values instead of thousands.

$enable-shadows: true;

// scss-docs-start colors-map
$colors: (
  // "blue": $blue,
  // "indigo": $indigo,
  // "purple": $purple,
  // "pink": $pink,
  // "red": $red,
  // "orange": $orange,
  // "yellow": $yellow,
  // "green": $green,
  // "teal": $teal,
  // "cyan": $cyan,
  // "black": $black,
  // "white": $white,
  // "gray": $gray-600,
  // "gray-dark": $gray-800
);

// scss-docs-start theme-color-variables
$primary: _.$color-primary;
$secondary: _.$color-orange-800;
$tertiary: _.$color-tertiary;
$success: _.$color-success;
$info: _.$color-info;
$warning: _.$color-warning;
$danger: _.$color-error;
$light: _.$color-grey-100;
$dark: _.$color-blue-800;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);
// scss-docs-end theme-colors-map

$body-bg: _.$color-background;
$body-color: _.$color-text;

// scss-docs-start grid-breakpoints
// same as Bootstrap default, needed to have shared breakpoints around the app.
$grid-breakpoints: (
  xs: _.$breakpoint-xs,
  sm: _.$breakpoint-sm,
  md: _.$breakpoint-md,
  lg: _.$breakpoint-lg,
  xl: _.$breakpoint-xl,
  xxl: _.$breakpoint-xxl) !default;
// scss-docs-end grid-breakpoints

$font-family-base: _.$font-family-base;
$font-size-root: _.$font-size-root;
$font-size-base: calc(_.$font-size-base / _.$font-size-root) * 1rem; // 1 rem <=> 16px
// $font-size-sm: $font-size-base * .875 !default;
// $font-size-lg: $font-size-base * 1.25 !default;

// $font-weight-lighter: lighter !default;
// $font-weight-light: 300 !default;
$font-weight-normal: _.$font-weight-base;
// $font-weight-semibold: 600 !default;
$font-weight-bold: _.$font-weight-bold;
// $font-weight-bolder: bolder !default;

// $font-weight-base: $font-weight-normal !default;

$h1-font-size: _.$font-size-h1;
$h2-font-size: _.$font-size-h2;
$h3-font-size: _.$font-size-h3;
$h4-font-size: _.$font-size-h4;
$h5-font-size: _.$font-size-h5;
$h6-font-size: _.$font-size-h6;

$headings-font-family: _.$font-family-header;
$headings-font-style: normal;
$headings-font-weight: 500;
// $headings-line-height: 1.2 !default;
$headings-color: _.$color-blue-800;

$small-font-size: _.$font-size-small;

// Tables
//
$table-cell-padding-y: 0.75rem; // 12 px
$table-cell-padding-x: 0.75rem; // 12 px

// Buttons + Forms
//
// scss-docs-start form-input-variables
$input-bg: #FFFFFF;

// scss-docs-start input-btn-variables
$input-btn-padding-y: 8px;
$input-btn-padding-x: 18px;
// $input-btn-font-family: null !default;
$input-btn-font-size: 16px;
// $input-btn-line-height: $line-height-base  !default;

$input-btn-focus-width: 2px;
// $input-btn-focus-color-opacity: .25 !default;
$input-btn-focus-color: _.$color-blue-600;
$input-btn-focus-blur: 0;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 6px;
$input-btn-padding-x-sm: 16px;
$input-btn-font-size-sm: 14px;

$input-btn-padding-y-lg: 11px;
$input-btn-padding-x-lg: 20px;
$input-btn-font-size-lg: 18px;

// $input-btn-border-width: $border-width  !default;
// scss-docs-end input-btn-variables

// scss-docs-start btn-variables
// $btn-padding-y: 16px;
// $btn-padding-x: 30px;
// $btn-font-family: $input-btn-font-family  !default;
// $btn-font-size: $input-btn-font-size  !default;
// $btn-line-height: $input-btn-line-height  !default;
// $btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

// $btn-padding-y-sm: $input-btn-padding-y-sm  !default;
// $btn-padding-x-sm: $input-btn-padding-x-sm  !default;
// $btn-font-size-sm: $input-btn-font-size-sm  !default;

// $btn-padding-y-lg: $input-btn-padding-y-lg  !default;
// $btn-padding-x-lg: $input-btn-padding-x-lg  !default;
// $btn-font-size-lg: $input-btn-font-size-lg  !default;

// $btn-border-width: none;

$btn-font-weight: 400;
// $btn-box-shadow: 0 0 30px 0 #dae1f7;
// $btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
// $btn-disabled-opacity: .65 !default;
// $btn-active-box-shadow: inset 0 3px 5px rgba($black, .125) !default;

// $btn-link-color: var(--#{$prefix}link-color) !default;
// $btn-link-hover-color: var(--#{$prefix}link-hover-color) !default;
// $btn-link-disabled-color: $gray-600  !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 4px;
$btn-border-radius-sm: 4px;
$btn-border-radius-lg: 4px;

// $btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// $btn-hover-bg-shade-amount: 15% !default;
// $btn-hover-bg-tint-amount: 35%; // orange-800 => orange-600 cirka
// $btn-hover-border-shade-amount: 20% !default;
// $btn-hover-border-tint-amount: 10% !default;
// $btn-active-bg-shade-amount: 20% !default;
// $btn-active-bg-tint-amount: 20% !default;
// $btn-active-border-shade-amount: 25% !default;
// $btn-active-border-tint-amount: 10% !default;
// scss-docs-end btn-variables

$link-color: _.$color-blue-500;
$link-decoration: none;
// $link-shade-percentage: 20% !default;
// $link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: underline;

// $stretched-link-pseudo-element: after !default;
// $stretched-link-z-index: 1 !default;

$navbar-padding-y: 0;
$navbar-brand-padding-y: 0;

$popover-bg: _.$color-blue-800;
$popover-header-color: _.$color-white;
$popover-body-color: _.$color-white;

// scss-docs-start badge-variables
$badge-font-size: _.$font-size-small;
// $badge-font-weight: $font-weight-bold !default;
// $badge-color: $white !default;
$badge-padding-y: 8px;
$badge-padding-x: 8px;
// $badge-border-radius: $border-radius !default;
// scss-docs-end badge-variables

@import "~bootstrap/scss/bootstrap";

/*
* Here starts the Bootstrap customization that is not possible with the exposed scss variables.
*/

/*
 * there is no display-font-family variable, so I override it here
 */
.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  font-family: _.$display-font-family;
}

// there is no `max-width: 0` in bootstrap, so I added one here for consistency.
// max-width-0 is useful when you want to ellipse a text inside a td-cell.
// see case #2 in https://stackoverflow.com/a/39149634
.mw-0 {
  max-width: 0;
}

/*
* Table
*/
*[class^="TableContainer"] {
  .table-filter {
    padding: 0 24px 24px 24px;
  }
}

table {
  font-size: _.$font-size-small;

  tr > {
    *:first-child {
      padding-left: 24px;
    }

    *:last-child {
      padding-right: 24px;
    }
  }
}

.table > :not(caption) > * > * button.btn-link {
  padding: 0 !important;
  border: 0;
}

/*
* Button overrides
* NB. ".btn" is needed to get an higher specificity
*/

.btn {
  &.btn-primary {
    background-color: _.$color-blue-800;

    &:hover {
      background-color: _.$color-blue-600;
      border-color: _.$color-blue-600;
    }

    &:focus {
      background-color: _.$color-blue-600;
    }
  }

  &.btn-secondary {
    color: _.$color-blue-800;

    &:focus {
      background-color: _.$color-orange-600;
    }
  }

  &.btn-link-secondary {
    color: _.$color-blue-800;
  }

  &.btn-tertiary {
    color: _.$color-blue-800;
    border: 1px solid _.$color-blue-100;

    &:hover {
      background-color: _.$background-color-blue10;
      border: 1px solid _.$color-blue-100;
    }
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;

    &:active {
      pointer-events: none;
    }
  }

  &.btn-lg {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.btn-sm {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &:not(.btn-sm):not(.btn-lg) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}


/*
* Input / Input group
*/
.input-group {
  &.input-group-lg {
    .input-group-text {
      > svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.input-group-sm {
    .input-group-text {
      > svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &:not(.input-group-sm):not(.input-group-lg) {
    .input-group-text {
      > svg {
        width: 20px;
        height: 20px;
      }
    }
  }

}

a,
input,
button,
select,
.form-control,
.form-select,
.form-check-input,
.btn {

  &:focus,
  &:focus-visible,
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: solid 2px _.$color-blue-300
  }
}

/*
* Badg pill / Pills
*/
.badge.rounded-pill {
  &[class*="bg-"] {
    font-weight: 400;
  }

  &.bg-secondary,
  &.bg-tertiary,
  &.bg-warning,
  &.bg-light {
    color: _.$color-blue-800;
  }

  &.bg-success {
    color: _.$color-success;
    background-color: #E6F4EF !important;
  }

  &.bg-warning {
    color: _.$color-warning;
    background-color: #FCF3E6 !important;
  }

  &.bg-danger {
    color: _.$color-error;
    background-color: #FCEAE8 !important;
  }

  &.bg-light {
    color: _.$color-blue-500;
    background-color: _.$background-color-blue50 !important;
  }
}

.tooltip-arrow {
  display: none!important;
}