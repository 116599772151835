@font-face {
  font-family: 'hermesfb';
  src: local("hermesfb-book"),
    url('../fonts/hermesfb/hermesfb_book-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'hermesfb';
  src: local("hermesfb-regular"),
    url('../fonts/hermesfb/hermesfb_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hermesfb';
  src: local("hermesfb-semibold"),
    url('../fonts/hermesfb/hermesfb_semibold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'hermesfb';
  src: local("hermesfb-bold"),
    url('../fonts/hermesfb/hermesfb_bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
