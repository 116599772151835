// .navStyle {
// background-color: #063342 !important;
// height: 120px;
// }

.navStyleBottom {
  background-color: #FFFFFF !important;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}

// .container {
// background-color: #063342;
// }

// .logoImage {
//   width: 166px;
// }

.navRight {
  height: 90px;
  width: 50px;
}

.navLeft {
  width: 500px;
}

.link {
  color: #F2F4F5 !important;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2%;
  line-height: 18px;
  padding-left: 0;
}

.linkBottom {
  color: #063342 !important;
}

.language {
  font-size: 12px;
}

.linkSpace {
  color: #F8992E !important;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2%;
  line-height: 18px;
  cursor: auto;
}

.link:hover {
  text-decoration: underline;
}

.upAndDown {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.up {
  display: flex;
  justify-content: flex-end;
}

.down {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.navShowOnMobile {
  display: none !important;
}

@media only screen and (max-width: 990px) {
  .up {
    flex-direction: column;
  }

  .down {
    flex-direction: column;
    justify-content: flex-start;
  }

  .navRight {
    height: auto;
  }

  .linkSpace {
    display: none !important;
  }

  .navLeft {
    align-items: flex-start !important;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .navRight {
    width: 100%;
  }

  .navStyleBottom {
    display: none !important;
  }

  .navShowOnMobile {
    display: block !important;
    align-items: flex-start !important;
    margin-bottom: 20px;
    margin-top: 10px;
    text-transform: uppercase;
  }
}
