body{margin-top:20px;}

.timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

.timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem
}

@media (min-width:768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
        content: "";
        display: block;
        border-top: 2px solid #8499A5;
        width: 3.46rem;
        position: absolute;
        left: 7.5rem;
        top: .8rem;
    }
    .timeline-steps .timeline-step:not(:first-child):before {
        content: "";
        display: block;
        border-top: 2px solid #8499A5;
        width: 3.8125rem;
        position: absolute;
        right: 7.5rem;
        top: .8rem;
    }
}

.timeline-steps .timeline-content {
    width: 10rem;
    text-align: center
}