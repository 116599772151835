@use 'styles/_variables.scss' as _;

$scrollbar-width: 16px;
$border-radius: 8px;

$color-primary: _.$color-blue-400;
$color-hover: _.$color-blue-500;

/* width */
::-webkit-scrollbar {
  width: $scrollbar-width;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  // box-shadow: inset 0 0 2px _.$color-primary;
  border-radius: $border-radius;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-primary;
  border-radius: $border-radius;

  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-hover;

  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}
