@use 'styles/_variables.scss' as _;
@use 'styles/_font-hermesfb.scss';
@use 'styles/_button.scss';
@use 'styles/_card.scss';
@use 'styles/_form.scss';
@use 'styles/_modal.scss';
@use 'styles/_scrollbar.scss';
@use 'styles/_svg.scss';
@use 'styles/_table.scss';
@import "~react-notifications-component/dist/scss/notification.scss";

body {
  font-family: _.$font-family-base;
  color: _.$color-blue-700;
}

.larger {
  font-size: _.$font-size-large;
}

.smaller {
  font-size: _.$font-size-smaller;
}

.bg-error {
  background-color: _.$color-error;
}

.background-blue50 {
  background-color: _.$background-color-blue50;
}

.bg-grey-300 {
  background-color: _.$color-grey-300;
}

.background-blue10 {
  background-color: _.$background-color-blue10;
}

.color-blue300 {
  color: _.$color-blue-300;
}

[role=button][tabindex^="-"] {
  cursor: default;
}

div[role="button"]:not([tabindex^="-"]),
div[role="button"]:not([tabindex^="-"]).tertiary {
  // border: 1px solid _.$color-blue-100;

  &:hover {
    background-color: _.$background-color-blue10;
    border-color: _.$color-blue-800;
  }

  &:focus,
  &:focus-visible,
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: solid 2px _.$color-blue-300
  }
}

div[role="button"].disabled {
  cursor: not-allowed;
  pointer-events: auto;
  background-color: _.$background-color-blue50;
}
