.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vh;
  height: 80vh;
  flex-direction: column;
}

.spinner {
  margin-bottom: 20px;
}
