@use 'styles/_variables.scss' as _;

span[role=button] > svg {
  &.active {
    color: _.$color-blue-800;
  }

  &:not(.active) {
    color: _.$color-blue-300;
  }

  &.white {
    color: _.$color-white;
  }
}

span[role=button]:hover > svg {
  color: _.$color-primary;
}

/* 
custom SVG classes to be used only in svg imported manually. 
For Material icons use their built-in attributes. 
*/
svg {
  &.primary {
    fill: _.$color-primary;
  }

  &.color-grey {
    fill: _.$color-blue-300;
  }

  &.size-s {
    width: 20px;
    height: 20px;
  }

  &.size-m {
    width: 24px;
    height: 24px;
  }

  &.size-l {
    width: 32px;
    height: 32px;
  }

  &.size-xl {
    width: 64px;
    height: 64px;
  }

  &.size-xxl {
    width: 128px;
    height: 128px;
  }
}
