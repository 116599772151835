@use 'styles/_variables.scss' as _;

.accordion-header {
  button {
    background-color:white;

    &:last-of-type {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    &:not(.collapsed) {
      color: none;
      background-color: none;
      box-shadow: none;
    }

    &:focus {
      border-color: none;
    }

    &:first-of-type {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
  }
}

.accordion-header-inner {
  button {
    &:last-of-type {
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
    }

    &:not(.collapsed) {
      color: none;
      background-color: transparent !important;
      box-shadow: none;
    }

    &:focus {
      border-color: none;
    }

    &:first-of-type {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
  }
}