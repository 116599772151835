/*
 * based on: 
 * https://www.figma.com/file/FSLZVJNeknOWbIh6Y29fPA/MVP---Iterasjon-2?node-id=108%3A15018&t=43QcDIO6ccckgycu-0
 *
 * Previous version:
 * https://www.figma.com/file/cPe8WOzQeJcmLVvlfYuph3/Multi-Designsystem
 * https://www.figma.com/file/nArWIFI62RFWu4TPnlGTrU/MVP
 *
 * WAVE validator: https://chrome.google.com/webstore/detail/wave-evaluation-tool/jbbplnpkjmmeebjpijfedlgcdilocofh
 */

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

$color-orange-800: #F8992E;
$color-orange-700: #F9A234;
$color-orange-600: #FAAB4B;
$color-orange-500: #FBB562;
$color-orange-400: #FDBF76;
$color-orange-300: #FDC98A;
$color-orange-200: #FFDCB4;
$color-orange-100: #FFE7CB;

// OBS. color-blue <=> color-bluegray
$color-blue-800: #063342;
$color-blue-700: #2D4651;
$color-blue-600: #425B67;
$color-blue-500: #58717D;
$color-blue-400: #6D8591;
$color-blue-300: #8499A5;
$color-blue-200: #B3C2CB;
$color-blue-100: #CBD6DD;

$color-grey-800: #58595B;
$color-grey-700: #6D6E71;
$color-grey-600: #818285;
$color-grey-500: #939598;
$color-grey-400: #A7A9AC;
$color-grey-300: #BCBDC0;
$color-grey-200: #D1D2D4;
$color-grey-100: #E7E7E8;

$color-error: #E02B1D;
$color-warning: #E48900;
$color-success: #009262;
$color-info: #0671E0;
$color-loading: #ABBED1;

$color-green-100: rgba(20, 190, 20, 0.1);

$background-color-blue50: #E6EAED;
$background-color-blue10: #F2F4F5;

/*
* named colors
* to be used in the application
*/

// 'Helvetica' is not availbale on Windows, as it's a licensed font.
// 'Nimbus Sans' is available on Linux/Ubuntu as Helvetica replacement.
$font-family-base: 'hermesfb', helvetica, 'Nimbus Sans', arial, sans-serif;
$font-family-header: 'hermesfb', helvetica, 'Nimbus Sans', arial, sans-serif;

$font-size-root: 16px; // rem
$font-size-base: 16px; // base text size
$font-size-small: 14px;
$font-size-large: 18px;
$font-size-smaller: 12px;

$font-weight-base: 300;
$font-weight-bold: 500;

$font-size-h1: 64px;
$font-size-h2: 36px;
$font-size-h3: 28px;
$font-size-h4: 20px;
$font-size-h5: 18px;
$font-size-h6: 16px;

/* Multiconsult sa at de skulle BYTTE den til noe annet */
$display-font-family: 'hermesfb';

$color-text: $color-blue-800;
$color-background: $background-color-blue10;
$color-white: #ffffff;

$color-primary: $color-blue-800;
$color-secondary: $color-orange-800;
$color-tertiary: #ffffff;
