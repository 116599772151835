@use 'styles/_variables.scss' as _;

.divider {
  font-size: 32px;
  text-decoration: underline;

  + * {
    border: 1px dashed _.$color-loading;
    margin-bottom: 16px;

    &:not(.fullscreen) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  // .border {
  //   border: 1px solid blue;
  // }

}

.example-circle {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
}

.example-square {
  min-width: 30px;
  min-height: 30px;
  border-radius: 0;
  text-align: center;
  padding: 4px
}
